<template>
  <div class="mb-5">
    <h2 class="ml-3 mb-4 text-left">OEM Brands</h2>
    <b-container class="text-center">
      <b-row>
        <b-col>
          <img :src="images.fuji" alt="fuji" />
        </b-col>
        <b-col><img :src="images.kani" alt="kani" /></b-col>
        <b-col
          ><img :src="images.thammachart" alt="thammachart seafood"
        /></b-col>
        <b-col><img :src="images.aro" alt="aro" /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { storage } from "../../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
export default {
  data() {
    return {
      images: {
        fuji: "",
        wasabio: "",
        kani: "",
        thammachart: "",
        aro: "",
      },
    };
  },
  created() {
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(storage, `factory/brands/${key}.png`)).then((url) => {
        this.images[key] = url;
      })
    );
  },
};
</script>

<style scoped>
img {
  width: 135px;
  margin: auto;
}
h2 {
  padding-left: 15px;
  font-size: 35px;
}
@media screen and (max-width: 390px) {
  img {
    /* width: 165px; */
  }
}
</style>
