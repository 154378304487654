<template>
  <div>
    <h2 class="p-3">OEM Brand</h2>
    <Banner />
    <Photos />
    <Brands class="m-5" />
  </div>
</template>

<script>
import Banner from "@/components/OEM/Banner.vue";
import Photos from "@/components/OEM/Photos.vue";
import Brands from "@/components/factory/Brands.vue";
export default {
  components: { Banner, Photos, Brands },
};
</script>

<style scoped></style>
