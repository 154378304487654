<template>
  <div>
    <img :src="images.b_head" alt="" />
    <b-carousel :interval="0" controls>
      <b-carousel-slide :img-src="images.b_set1"></b-carousel-slide>
      <b-carousel-slide :img-src="images.b_set2"> </b-carousel-slide>
    </b-carousel>

    <img :src="images.w_head" alt="" />
    <img :src="images.w_set1" alt="" />
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";
export default {
  data() {
    return {
      images: {
        b_head: "",
        b_set1: "",
        b_set2: "",
        w_head: "",
        w_set1: "",
      },
    };
  },
  created() {
    const firebaseApp = firebaseApp;
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(getStorage(firebaseApp), `OEM/${key}.png`)).then(
        (url) => {
          this.images[key] = url;
        }
      )
    );
  },
};
</script>

<style scoped>
div {
  /* width: 80%; */
}
/* img {
  height: 70%;
  width: 70%;
}
.carousel {
  width: 70%;
  margin: auto;
} */
img {
  width: 100%;
  height: 100%;
}
</style>
